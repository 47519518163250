<template>
  <router-view />
</template>

<script>
export default {
  name: "Reportes",
  data: () => ({
    items: [],
    menu: 0,
  }),
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>
